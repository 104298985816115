import { useStaticQuery, graphql } from "gatsby";
import { useAppState } from "@state";

const query = graphql`
  query HeaderQuery {
    craft {
      globalSet(handle: "header") {
        ... on Craft_header_GlobalSet {
          enableAbTest
          headerLinks {
            ... on Craft_headerLinks_link_BlockType {
              uid
              headLink {
                text
                url
              }
              sublinks {
                ... on Craft_sublinks_BlockType {
                  uid
                  sublink {
                    text
                    url
                  }
                }
              }
            }
          }
          button: link0 {
            text
            url
          }
          secondaryButton: link1 {
            text
            url
          }
        }
      }
      # b: globalSet(handle: "header", siteId: 2) {
      #   ... on Craft_header_GlobalSet {
      #     headerLinks {
      #       ... on Craft_headerLinks_link_BlockType {
      #         uid
      #         headLink {
      #           text
      #           url
      #         }
      #         sublinks {
      #           ... on Craft_sublinks_BlockType {
      #             uid
      #             sublink {
      #               text
      #               url
      #             }
      #           }
      #         }
      #       }
      #     }
      #     button: link0 {
      #       text
      #       url
      #     }
      #     secondaryButton: link1 {
      #       text
      #       url
      #     }
      #   }
      # }
    }
  }
`;

const dataResolver = ({ globalSet, b }, test) => {
  const { headerLinks, button, secondaryButton } =
    test && globalSet.enableAbTest ? b : globalSet;
  const nav = headerLinks?.map(links => {
    return {
      ...links?.headLink,
      children: links?.sublinks?.map(sub => ({
        ...sub?.sublink,
        uid: sub?.uid,
      })),
      uid: links.uid,
    };
  });
  if (button?.url) nav.push({ ...button, color: "purple", button: true });
  if (secondaryButton?.url)
    nav.push({
      ...secondaryButton,
      color: "white",
      button: true,
      outline: true,
    });
  return {
    nav,
  };
};

const useData = () => {
  const [{ layout }] = useAppState();
  const { test } = layout;

  const { craft: data } = useStaticQuery(query);
  return dataResolver(data, test);
};

export default useData;
